import React, { Fragment, useContext } from 'react';
import type { Dispatch } from 'react';
import { Subscribe } from 'unstated';

import { token } from '@atlaskit/tokens';
import type { CollabEditOptions } from '@atlaskit/editor-common/collab';

import { FlagsStateContainer } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import { EditContentButton } from '@confluence/edit-button';
import { FavouriteButton } from '@confluence/favourite-button';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { WatchDialog } from '@confluence/watch-dialog';
import { WATCH_BUTTON_METRIC } from '@confluence/watch-dialog/entry-points/pageSegmentLoadMetrics';
import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog';
import { PageRestrictionsContextProvider } from '@confluence/page-restrictions-context';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { RestrictionsDialogTriggerWrapper } from '@confluence/restrictions';
import { CreatePageModalContextProvider, QuickSummaryPopup } from '@confluence/quick-summary';
import { ContentOwnershipContextProvider } from '@confluence/content-ownership';
import type { ShareContentType } from '@confluence/share';
import {
	type ContentType,
	type ContentMode,
	useIsStarringEnabled,
	useIsWatchDialogEnabled,
	VALID_OPERATION_TARGETS,
} from '@confluence/content-types-utils';
import {
	useIsClassificationEnabledForContentTypeExpansion,
	useIsEllipsisClassifyEnabled,
} from '@confluence/data-classification/entry-points/hooks';
import { isExternalShareRequest } from '@confluence/external-share-utils';
import { LivePagesModeChanger } from '@confluence/live-pages-features/entry-points/LivePagesModeChanger';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PublishButtonWithMoreOptions } from '@confluence/publish-modal';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import type { WhiteboardAIAgentServiceInterface } from '@confluence/whiteboard-types/entry-points/WhiteboardAIAgentService';
import { SPAViewContext } from '@confluence/spa-view-context';

import { useEditorObjectHeaderContext } from './EditorObjectHeaderContext';
import { ContentStatusComponent } from './content-types-actions/ContentStatus';
import { CollaboratorsComponent } from './content-types-actions/Collaborators';
import { MainActionsComponent } from './content-types-actions/MainActions';
import { MoreActionsComponent } from './content-types-actions/MoreActions';
import { WhiteboardTeammateButton } from './content-types-actions/whiteboards/WhiteboardTeammateButton';
import { ContentTypesWatchDialogButtonLoader } from './ContentTypesWatchDialogButton';
import type {
	ContentActions,
	HeaderItems,
	ContentTypesHeaderQueryNode,
} from './ContentTypesHeader';
import type { HeaderFeaturesType } from './ContentTypesHeaderFeatures';
import { Presence } from './object-header-items/Presence';
import { EditorCloseButton } from './object-header-items/EditorCloseButton';
import { UnpublishedChangesOrLastEdited } from './object-header-items/UnpublishedChangesOrLastEdited';
import { useDisableEditorActions } from './useDisableEditorActions';
import { useObjectHeaderVersion } from './useObjectHeaderVersion';

export type HeaderItemData = {
	contentId: string;
	contentType: ShareContentType;
	contentMode: ContentMode | undefined;
	contentActions?: ContentActions;
	handleRegisterOnShowCollaboratorSpotlight?: (callback: () => void) => void;
	handleRegisterOnShowHeaderSpotlight?: (callback: () => void) => void;
	allowedFeatures: HeaderFeaturesType;
	contentData: ContentTypesHeaderQueryNode | null | undefined;
	renderCopyLink: () => React.JSX.Element | undefined;
	renderShare: () => React.JSX.Element;
	showVersionHistorySpotlight?: boolean;
	setShowVersionHistorySpotlight?: (value: boolean) => void;
	collabEditOption?: CollabEditOptions;
	isSpaceOverviewPage: boolean;
	readOnly?: boolean;
	isConvertToFolderHighlighted?: boolean;
	setIsConvertToFolderHighlighted?: Dispatch<React.SetStateAction<boolean>>;
	whiteboardAIAgentService?: WhiteboardAIAgentServiceInterface;
};

export const useHeaderItems = ({
	contentId,
	contentType,
	contentMode,
	contentActions,
	handleRegisterOnShowCollaboratorSpotlight,
	handleRegisterOnShowHeaderSpotlight,
	allowedFeatures,
	contentData,
	renderCopyLink,
	renderShare,
	showVersionHistorySpotlight,
	setShowVersionHistorySpotlight,
	collabEditOption,
	isSpaceOverviewPage,
	isConvertToFolderHighlighted = false,
	setIsConvertToFolderHighlighted,
	whiteboardAIAgentService,
}: HeaderItemData): HeaderItems[] => {
	const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
		contentId,
	});
	const isWatchDialogEnabledForContentType = useIsWatchDialogEnabled(contentType);
	const isStarringEnabledForContentType = useIsStarringEnabled(contentType, contentMode);
	const { isEllipsisClassifyEnabled } = useIsEllipsisClassifyEnabled();
	const isExternal = isExternalShareRequest();
	const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible();
	const { isAnonymous } = useContext(SPAViewContext);

	const {
		isPublishingContent,
		hasEditPermission,
		onClickPublish,
		editorTitle,
		saveDraft,
		isUnpublishedDraft,
		shouldRenderPreview,
		onPreviewClicked,
		retrieveEditorTitleAndContent,
	} = useEditorObjectHeaderContext();

	const {
		shouldDisableEditorButtons,
		isPublishDisabledForPublishModal,
		isPublishDisabled,
		pageIsExternallyShared,
	} = useDisableEditorActions(contentId, contentMode);

	const isClassicEdit =
		(contentType === 'page' || contentType === 'blogpost') &&
		(contentMode === 'draft' || contentMode === 'edit');

	const isCompanyHub = isCompanyHubSpaceKey(contentData?.space?.key);

	const isClassificationEnabled = useIsClassificationEnabledForContentTypeExpansion(
		contentData?.type ?? '',
	);
	const isArchived = contentData?.status === 'archived';

	const version = useObjectHeaderVersion({ contentData, useDraftVersion: isClassicEdit });

	const readOnly =
		isExternal ||
		!contentData?.operations?.some(
			(operation) =>
				operation?.targetType &&
				VALID_OPERATION_TARGETS.includes(operation?.targetType as ContentType) &&
				operation?.operation === 'update',
		);

	const isClassificationAllowedInMoreActions =
		!readOnly && isClassificationEnabled && isEllipsisClassifyEnabled;
	const moreActionsAllowedFeatures = isClassificationAllowedInMoreActions
		? { ...allowedFeatures, showClassificationMenuAction: true }
		: allowedFeatures;

	const hasArchivePermission = contentData?.operations?.some((operation) => {
		return operation?.targetType === contentType && operation?.operation === 'archive';
	});

	const isWhiteboardDigitalTeammateEnabled =
		contentType === 'whiteboard' &&
		isRovoEnabledForContent &&
		fg('cc-ai-whiteboards-enable-digital-teammate');

	const isWhiteboardSummaryEnabled =
		contentType === 'whiteboard' && isAIEnabledForContent && !isWhiteboardDigitalTeammateEnabled;

	const whiteboardSummaryButtonStyles = fg('cc_smarts_white_summarize_button')
		? { border: `${token('border.width', '1px')} solid ${token('color.border')}` }
		: { standardButton: true };

	return fg('confluence_frontend_object_header')
		? [
				{
					name: 'lastEdited',
					shouldShowSeparator: false,
					shouldRender:
						contentType !== 'embed' && contentType !== 'database' && !isSpaceOverviewPage,
					children: (
						<UnpublishedChangesOrLastEdited
							contentId={contentId}
							version={version}
							contentType={contentType}
							contentMode={contentMode}
							spaceKey={contentData?.space?.key ?? ''}
						/>
					),
				},
				{
					// Presence only supports pages and blogposts right now. When it supports content types (whiteboard & dbs), we can remove the "collaborators" item below.
					name: 'presence',
					shouldShowSeparator: false,
					shouldRender:
						(contentType === 'page' || contentType === 'blogpost') && contentMode !== 'archived',
					children: (
						<Presence
							contentId={contentId}
							contentType={contentType}
							contentMode={contentMode}
							collabEditOption={collabEditOption}
						/>
					),
				},
				{
					// collaborators item shows collaborators for whiteboards only. It doesn't have support for dbs yet.
					name: 'collaborators',
					shouldShowSeparator: false,
					shouldRender: !!contentActions?.collaborators && contentType === 'whiteboard',
					children: (
						<CollaboratorsComponent
							collaboratorData={contentActions?.collaborators!}
							handleRegisterOnShowCollaboratorSpotlight={handleRegisterOnShowCollaboratorSpotlight}
						/>
					),
				},
				{
					name: 'mainActions', // this handles external link for embed
					shouldShowSeparator: false,
					shouldRender:
						!!contentActions?.mainActions &&
						!!allowedFeatures.showMainActions &&
						fg('open_in_new_tab_button_update'),
					children:
						!!contentActions?.mainActions && !!allowedFeatures.showMainActions ? (
							<MainActionsComponent mainActions={contentActions.mainActions.mainActions} />
						) : null,
				},
				{
					name: 'summary',
					shouldShowSeparator: true,
					shouldRender: isWhiteboardSummaryEnabled,
					children: (
						<CreatePageModalContextProvider
							contentId={contentId}
							spaceKey={contentData?.space?.key}
							contentTitle={contentData?.title}
						>
							<QuickSummaryPopup
								contentId={contentId}
								spaceKey={contentData?.space?.key || undefined}
								contentType={contentType}
								source={contentType}
								buttonStyles={{ border: 'none' }}
								contentPath={window.location.pathname}
							/>
						</CreatePageModalContextProvider>
					),
				},
				{
					name: 'whiteboard-teammate',
					shouldShowSeparator: true,
					shouldRender: isWhiteboardDigitalTeammateEnabled,
					children: (
						<WhiteboardTeammateButton whiteboardAIAgentService={whiteboardAIAgentService} />
					),
				},
				{
					name: 'editIcon',
					shouldShowSeparator: false,
					shouldRender:
						(contentType === 'page' || contentType === 'blogpost') && contentMode === 'view',
					children: <EditContentButton contentId={contentId} />,
				},
				{
					name: 'viewEditToggle',
					shouldShowSeparator: false,
					shouldRender:
						Boolean(contentMode === 'live' && hasEditPermission) &&
						!fg('cc_live_pages_toggle_move'),
					children: (
						<Subscribe to={[FlagsStateContainer]}>
							{(flags: FlagsStateContainer) => (
								<LivePagesModeChanger
									contentId={contentId}
									isDisabled={!!shouldDisableEditorButtons}
									flags={flags}
									analyticsSource="livePageScreen"
								/>
							)}
						</Subscribe>
					),
				},
				{
					name: 'update',
					shouldShowSeparator: false,
					shouldRender: isClassicEdit,
					children: (
						<Subscribe to={[FlagsStateContainer]}>
							{(flags: FlagsStateContainer) => (
								<PublishButtonWithMoreOptions
									onClickPublish={onClickPublish}
									publishButtonDisabled={isPublishDisabledForPublishModal ?? isPublishDisabled}
									title={editorTitle}
									onClickPreview={onPreviewClicked}
									userViewingPreview={shouldRenderPreview}
									retrieveEditorTitleAndContent={retrieveEditorTitleAndContent}
									isUnpublishedDraft={isUnpublishedDraft}
									flags={flags}
									saveDraft={saveDraft}
									contentType={contentType}
									savingInProgress={isPublishingContent}
									shouldShowWorldIcon={
										pageIsExternallyShared &&
										!(
											fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled
										)
									}
									shouldShowFooterButtons
								/>
							)}
						</Subscribe>
					),
				},
				{
					name: 'close',
					shouldShowSeparator: false,
					shouldRender: contentMode === 'draft' || contentMode === 'edit',
					children: (
						<EditorCloseButton
							contentType={contentType}
							contentId={contentId}
							areMainButtonsDisabled={shouldDisableEditorButtons}
							isUnpublishedDraft={contentMode === 'draft'}
						/>
					),
				},
				{
					name: 'share',
					shouldShowSeparator: false,
					shouldRender: !isAnonymous && !isArchived && !!allowedFeatures.showShare && !isCompanyHub,
					children: renderShare(),
				},
				{
					name: 'contentRestrictions',
					shouldShowSeparator: false,
					shouldRender: !isAnonymous && !!allowedFeatures.showContentRestrictions && isArchived,
					children: (
						<PageRestrictionsContextProvider contentId={contentId}>
							<ErrorBoundary attribution={Attribution.PERMISSIONS_EXPERIENCE}>
								<RestrictionsDialogTriggerWrapper
									contentId={contentId}
									key="restrictions-dialog-trigger"
									triggeredFrom="fromContentType"
								/>
							</ErrorBoundary>
						</PageRestrictionsContextProvider>
					),
				},
				{
					name: 'copy',
					shouldShowSeparator: isWhiteboardSummaryEnabled,
					shouldRender:
						fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled
							? isArchived
							: allowedFeatures.showCopy,
					children: renderCopyLink(),
				},
				{
					name: 'moreActions',
					shouldShowSeparator: false,
					shouldRenderInContainer: false,
					shouldRender: !!allowedFeatures.showMoreActions,
					children: (
						<ContentOwnershipContextProvider>
							<Subscribe to={[FlagsStateContainer]}>
								{(flags: FlagsStateContainer) => (
									<MoreActionsComponent
										moreActions={contentActions?.moreActions}
										contentId={contentId}
										contentType={contentType}
										contentMode={contentMode}
										spaceKey={contentData?.space?.key ?? ''}
										allowedFeatures={moreActionsAllowedFeatures}
										hasArchivePermission={hasArchivePermission}
										handleRegisterOnShowHeaderSpotlight={handleRegisterOnShowHeaderSpotlight}
										setShowVersionHistorySpotlight={setShowVersionHistorySpotlight}
										showVersionHistorySpotlight={showVersionHistorySpotlight}
										flags={flags}
										contentData={contentData}
										readOnly={readOnly}
										isConvertToFolderHighlighted={isConvertToFolderHighlighted}
										setIsConvertToFolderHighlighted={setIsConvertToFolderHighlighted}
									/>
								)}
							</Subscribe>
						</ContentOwnershipContextProvider>
					),
				},
			]
		: [
				{
					name: 'status',
					shouldShowSeparator: false,
					shouldRender: !!contentActions?.contentStatus,
					children: <ContentStatusComponent contentStatus={contentActions?.contentStatus!} />,
				},
				{
					name: 'collaborators',
					shouldShowSeparator: true,
					shouldRender: !!contentActions?.collaborators && contentType !== 'database',
					children: (
						<CollaboratorsComponent
							collaboratorData={contentActions?.collaborators!}
							handleRegisterOnShowCollaboratorSpotlight={handleRegisterOnShowCollaboratorSpotlight}
						/>
					),
				},
				{
					name: 'mainActions',
					shouldShowSeparator: false,
					shouldRender:
						!!contentActions?.mainActions &&
						!!allowedFeatures.showMainActions &&
						fg('open_in_new_tab_button_update'), // For FG cleanup, remove this line and remove the second 'mainActions' item.
					children:
						!!contentActions?.mainActions && !!allowedFeatures.showMainActions ? (
							<MainActionsComponent mainActions={contentActions.mainActions.mainActions} />
						) : null,
				},
				{
					name: 'favourite',
					shouldShowSeparator: !isWatchDialogEnabledForContentType,
					shouldRender: isStarringEnabledForContentType && !!allowedFeatures.showFavourite,
					children: <FavouriteButton contentId={contentId} />,
				},
				{
					name: 'watchDialog',
					shouldShowSeparator: true,
					shouldRender: isWatchDialogEnabledForContentType && !!allowedFeatures.showWatchDialog,
					children: (
						<Fragment>
							<PageSegmentLoadStart key={contentId} metric={WATCH_BUTTON_METRIC} />
							<WatchDialog
								contentId={contentId}
								defaultTab={MANAGE_WATCHER_TAB_TYPES.PAGE}
								customTrigger={ContentTypesWatchDialogButtonLoader}
								useShortcut
							/>
						</Fragment>
					),
				},
				{
					name: 'mainActions',
					shouldShowSeparator: false,
					shouldRender:
						!!contentActions?.mainActions &&
						!!allowedFeatures.showMainActions &&
						!fg('open_in_new_tab_button_update'),
					children:
						!!contentActions?.mainActions && !!allowedFeatures.showMainActions ? (
							<MainActionsComponent mainActions={contentActions.mainActions.mainActions} />
						) : null,
				},
				{
					name: 'contentRestrictions',
					shouldShowSeparator: isArchived,
					shouldRender:
						!!allowedFeatures.showContentRestrictions &&
						!(fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled),
					children: (
						<PageRestrictionsContextProvider contentId={contentId}>
							<ErrorBoundary attribution={Attribution.PERMISSIONS_EXPERIENCE}>
								<RestrictionsDialogTriggerWrapper
									contentId={contentId}
									key="restrictions-dialog-trigger"
									triggeredFrom="fromContentType"
								/>
							</ErrorBoundary>
						</PageRestrictionsContextProvider>
					),
				},
				{
					name: 'copy',
					shouldShowSeparator: isWhiteboardSummaryEnabled,
					shouldRender:
						fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled
							? isArchived
							: allowedFeatures.showCopy,
					children: renderCopyLink(),
				},
				{
					name: 'summary',
					shouldShowSeparator: true,
					shouldRender: isWhiteboardSummaryEnabled,
					children: (
						<CreatePageModalContextProvider
							contentId={contentId}
							spaceKey={contentData?.space?.key}
							contentTitle={contentData?.title}
						>
							<QuickSummaryPopup
								contentId={contentId}
								spaceKey={contentData?.space?.key || undefined}
								contentType={contentType}
								source={contentType}
								buttonStyles={whiteboardSummaryButtonStyles}
								contentPath={window.location.pathname}
							/>
						</CreatePageModalContextProvider>
					),
				},
				{
					name: 'whiteboard-teammate',
					shouldShowSeparator: true,
					shouldRender: isWhiteboardDigitalTeammateEnabled,
					children: (
						<WhiteboardTeammateButton whiteboardAIAgentService={whiteboardAIAgentService} />
					),
				},
				{
					name: 'share',
					shouldShowSeparator: true,
					shouldRender: !isArchived && !!allowedFeatures.showShare,
					children: renderShare(),
				},
				{
					name: 'moreActions',
					shouldShowSeparator: false,
					shouldRenderInContainer: false,
					shouldRender: !!allowedFeatures.showMoreActions,
					children: (
						<ContentOwnershipContextProvider>
							<Subscribe to={[FlagsStateContainer]}>
								{(flags: FlagsStateContainer) => (
									<MoreActionsComponent
										moreActions={contentActions?.moreActions}
										contentId={contentId}
										contentType={contentType}
										spaceKey={contentData?.space?.key ?? ''}
										allowedFeatures={moreActionsAllowedFeatures}
										hasArchivePermission={hasArchivePermission}
										handleRegisterOnShowHeaderSpotlight={handleRegisterOnShowHeaderSpotlight}
										setShowVersionHistorySpotlight={setShowVersionHistorySpotlight}
										showVersionHistorySpotlight={showVersionHistorySpotlight}
										contentData={contentData}
										flags={flags}
									/>
								)}
							</Subscribe>
						</ContentOwnershipContextProvider>
					),
				},
			];
};
